<template>
    <!-- begin::Scrolltop -->
    <div id="kt_scrolltop" ref="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
        <span class="svg-icon">
            <inline-svg src="/media/icons/duotune/arrows/arr066.svg"/>
        </span>
    </div>
    <!-- end::Scrolltop -->
</template>

<script>
import {ScrollTopComponent} from "@/assets/js/components/_ScrollTopComponent";

export default {
    name: "KTScrollTop",
    components: {},
    mounted() {
        ScrollTopComponent.reinitialization();
    }
}
</script>
